import React, {useEffect, useState} from "react";
import './css/Invitation.css';
import Response from "./Response";
import {GoogleSpreadsheet} from "google-spreadsheet";

const Invitation = props => {
  const [showResponse, setShowResponse] = useState(false);
  const [responseAccept, setResponseAccept] = useState(null);
  const [alreadyResponded, setAlreadyResponded] = useState(null);
  const [loading, setLoading] = useState(false);

  const goBack = () => {
    setResponseAccept(null);
    setShowResponse(false);
  }

  // initally set alreadyResponded state from props
  useEffect(() => {
    setAlreadyResponded(props.responded);
  }, [props.responded]);

  const handleDeleteResponse = () => {
    const deleteResponse = async () => {
      const SHEET_ID = process.env.REACT_APP_SHEET_ID;
      const doc = new GoogleSpreadsheet(SHEET_ID);
      const credentials = require('./credentials.json')

      await doc.useServiceAccountAuth(credentials);
      await doc.loadInfo();

      const sheet = doc.sheetsByIndex[0];
      const rows = await sheet.getRows();

      const today = new Date();
      const responseDate = today.getDate() + "." + (today.getMonth() + 1) + "." + today.getFullYear() + " " + today.getHours() + ":" + today.getMinutes();

      rows[props.rowNumber].Zusage = 0;
      rows[props.rowNumber].Absage = false;
      rows[props.rowNumber].cancelDate = responseDate;
      rows[props.rowNumber].Antwort = '';
      rows[props.rowNumber].Ankunft = '';
      rows[props.rowNumber].SchlafMi = 0;
      rows[props.rowNumber].SchlafDo = 0;
      rows[props.rowNumber].Veggie = '';

      await rows[props.rowNumber].save();
    }

    deleteResponse()
      .then(() => {
        setAlreadyResponded(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error", err);
      });
  }

  return (
    <div>
      <h1 className="title">Willkommen, {props.info[0]}! <span role="img" aria-label="party-emoji">🎉</span></h1>
      <div className="invContainer">
        <div className="spaceBelow">
          Ich würde mich sehr freuen, am <b>12. August 2021</b> auf der <b>Rottenmanner
          Hütte</b> mit {props.plural ? 'Euch' : 'Dir'} meinen <b><i>30. Geburtstag</i></b> feiern zu dürfen! <span
          role="img" aria-label="party emoji">🥳</span>
        </div>
        {alreadyResponded ?
          <div>
            {props.zusage ? <h4>Danke für {props.plural ? 'Eure' : 'Deine'} Zusage, ich freue mich! 🤩</h4> : ''}
            {props.absage ? <h4>Danke für {props.plural ? 'Eure' : 'Deine'} Rückmeldung - schade, vielleicht ein anderes
              Mal!</h4> : ''}
            <button className="inlineBtn inlineBtn--lg outlineBtn backButton--red" onClick={() => {
              setLoading(true);
              handleDeleteResponse();
            }}>{loading ? <i>Laden...</i> :
              <span>{props.plural ? 'Unsere' : 'Meine'} Rückmeldung <i>ändern</i></span>}</button>
          </div> :
          showResponse ?
            <Response info={props.info} plural={props.plural} pax={props.pax} reinfeier={props.reinfeier}
                      accept={responseAccept} rowNumber={props.rowNumber} goBack={() => goBack()}/> :
            <>
              <div
                className="acceptButton"
                onClick={() => {
                  setShowResponse(true);
                  setResponseAccept(true);
                }}
              >
              <span
                className="acceptButtonText">Ja, {props.plural ? 'wir sind' : 'ich bin'} dabei! <span role="img"
                                                                                                      aria-label="champaign emoji">🍾</span></span>
              </div>
              <div
                className="declineButton"
                onClick={() => {
                  setShowResponse(true);
                  setResponseAccept(false);
                }}
              >
              <span
                className="declineButtonText">Nein, {props.plural ? 'wir kommen' : 'ich komme'} nicht. <span role="img"
                                                                                                             aria-label="sad emoji"/></span>
              </div>
            </>
        }
        <div>
          <h2><span role="img" aria-label="location pin">📍</span> Rottenmanner Hütte (1651m)</h2>
          <a href="https://goo.gl/maps/KNsSegYtbVT6CwZv5" target="_blank"
             rel="noreferrer">https://goo.gl/maps/KNsSegYtbVT6CwZv5</a>
          <div className="mapContainer">
            <iframe className="gmap" title="google-maps-rottenmanner-huette"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d93094.30676635973!2d14.33010080718067!3d47.49716984698265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47719fd7edcbbbff%3A0x961b7ee506b5fa2c!2sRottenmanner%20H%C3%BCtte!5e0!3m2!1sde!2sat!4v1617879365309!5m2!1sde!2sat"
                    allowFullScreen="" loading="lazy"/>
          </div>
          <h2><span role="img" aria-label="highway-emoji">🛣</span> Anfahrt</h2>
          <a href="https://www.rottenmannerhuette.at/anfahrt" target="_blank"
             rel="noreferrer">https://www.rottenmannerhuette.at/anfahrt</a>
          <h2 id="scheduleHeadline"><span role="img" aria-label="clock-emoji">⏰</span> Plan</h2>
          <div id="infoContainer" className="infoContainer">
            <div className="arrival"><b>Anreise</b> {props.reinfeier ?
              <span>am <u>11. August abends</u> <i>oder</i></span> : ''} am <u>12. August tagsüber</u></div>
            <div className="scheduleFlex">
              {props.reinfeier ?
                <ul className="scheduleFlexItemL">
                  <li><h4><u>Mi, 11. August</u></h4></li>
                  <li><b>Reinfeier</b> ab ca. 19 Uhr</li>
                </ul> : ''}
              <ul
                className={props.reinfeier ? 'scheduleFlexItemR scheduleFlexItemR--both' : 'scheduleFlexItemR scheduleFlexItemR--only'}>
                <li><h4><u>Do, 12. August</u></h4></li>
                <li><b>Mini-Wanderung</b> gegen 13 Uhr<br/></li>
                <li><b>Kaffee</b> ca. 16 Uhr</li>
                <li>... mit unmittelbarem Übergang in einen <b>herrlichen Abend!</b></li>
              </ul>
              <div className="scheduleFlexItemU">
                Gute Laune, Musik, ggfs. Darbietungen aller Art sind <b>sehr herzlich willkommen!</b> <span role="img"
                                                                                                            aria-label="party-emoji">🎉</span>
              </div>
            </div>
          </div>
          <h2><span role="img" aria-label="clock-emoji">🛌</span> Übernachtung</h2>
          <div className="infoContainer">
            <ul className="infoList">
              <li>... ist in der Hütte <b>möglich</b>.</li>
              <li>Es gibt ca. <b>50 Schlafplätze</b>, die allermeisten davon in <b>Matratzenlagern</b>.</li>
              <li>Davon sind <b>noch <i>ca. {props.avThu} frei</i></b>, daher ...</li>
              <li>... bitte <b>baldigst <a className="bescheidGeben" onClick={() => {
                setShowResponse(true);
                setResponseAccept(true);
                document.querySelector('.title').scrollIntoView({block: "end", behavior: "smooth"});
              }}>Bescheid geben</a></b>, danke 😊
              </li>
            </ul>

            <div className="attention">
              <b><span style={{color: "red"}}>Achtung, </span>Anmerkung von der Wirtin: </b><br/>
              <span className="smallText">"Für die Übernachtung in den Zimmern, sowie in den Matrazenlagern sind Hüttenschlafsäcke mitzubringen.
              Diese kann man auch für eine Gebühr von 5 € bei uns ausleihen bzw. um 20,90 € kaufen."</span>
            </div>

            <div className="smallText alignCenter">
              <b>PS:</b> Der Feiernde freut sich über einen Bar-Zuschuss zu den
              Übernachtungskosten (die pro Person & Nacht 26€ betragen) <span role="img" aria-label="smiley">😉</span>
            </div>

          </div>
        </div>

      </div>
    </div>
  );
}

export default Invitation;
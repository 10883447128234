import React, {useState, useEffect} from "react";
import {GoogleSpreadsheet} from 'google-spreadsheet';
import Invitation from "./Invitation";
import {Helmet} from "react-helmet";

const Invited = props => {
  const [info, setInfo] = useState(null);
  const [initialLoadAttempts, setInitialLoadAttempts] = useState(0);
  const [error, setError] = useState(false);
  const [plural, setPlural] = useState(true);
  const [reinfeier, setReinfeier] = useState(false);
  const [responded, setResponded] = useState(false);
  const [zusage, setZusage] = useState(null);
  const [absage, setAbsage] = useState(null);
  const [pax, setPax] = useState(null);
  const [avWed, setAvWed] = useState(null);
  const [avThu, setAvThu] = useState(null);

  const rowNumber = parseInt(props.code.slice(props.code.length - 3)) - 2;

  useEffect(() => {
    if (rowNumber > 120 || rowNumber < 4) {
      setError(true);
    } else {
      // console.log("Rownumber good", initialLoadAttempts);
      if (initialLoadAttempts < 3 && !info) {
        const connectGoogle = async (rowNumber) => {
          const SHEET_ID = process.env.REACT_APP_SHEET_ID;
          const doc = new GoogleSpreadsheet(SHEET_ID);
          const credentials = require('./credentials.json')

          await doc.useServiceAccountAuth(credentials);
          await doc.loadInfo();

          const sheet = doc.sheetsByIndex[0];
          const sheetRows = await sheet.getRows();
          const rows = sheetRows.map(a => a._rawData);
          // console.log("All data", rows);
          const infoRow = sheetRows[rowNumber]._rawData;
          // console.log(infoRow[3] === props.code);
          if (infoRow[3] === props.code) {
            const today = new Date();
            sheetRows[rowNumber].login = today.getDate() + "." + (today.getMonth() + 1) + "." + today.getFullYear();
            sheetRows[rowNumber].loginCount = sheetRows[rowNumber].loginCount ? parseInt(sheetRows[rowNumber].loginCount) + 1 : 1;
            // console.log("Setting Info", infoRow);
            setInfo(infoRow);
            setPlural(infoRow[2] > 1);
            setReinfeier(infoRow[5] !== '');
            setPax(parseInt(infoRow[2]));
            setAvWed(parseInt(rows[2][13]));
            setAvThu(parseInt(rows[2][14]));
            if (infoRow[9]) {
              setResponded(true);
              infoRow[10] ? setZusage(parseInt(infoRow[10])) : setZusage(null);
              infoRow[11] ? setAbsage(true) : setAbsage(null);
            }
            await sheetRows[rowNumber].save();
            // console.log('Wednesday left', rows[2][13], 'Thursday left', rows[2][14]);
            // console.log('Plural', infoRow[2] > 1, 'Reinfeier', infoRow[5] !== '');
          } else {
            setError(true);
            setInitialLoadAttempts(initialLoadAttempts + 1);
          }
        }

        connectGoogle(rowNumber)
          .then(() => {
            console.log('Successfully connected GS');
          })
          .catch(() => {
            setInitialLoadAttempts(initialLoadAttempts + 1);
          });
      } else if (info) {
        console.log("SUCCESS");
      } else {
        console.log("Too many unsuccessful tries.");
        setError(true);
      }
    }
  }, [props.code, rowNumber, initialLoadAttempts, error, info]);

  return (
    <div className="App">
      <Helmet>
        <title>Marcus feiert - Geburtstag! 🎉</title>
        <meta name="description" content="Herzliche Einladung zur 30er-Feier am 12. August 2021 auf der Rottenmanner Hütte!" />
        <meta property="og:title" content="Marcus feiert - Geburtstag! 🎉" />
        <meta property="og:url" content={window.location.href} />
        <meta name="description" content="Herzliche Einladung: 30er-Feier am 12. August 2021 @ Rottenmanner Hütte!" />
        <meta property="og:description" content="Herzliche Einladung: 30er-Feier am 12. August 2021 @ Rottenmanner Hütte!" />
        <meta property="og:type" content="website" />
      </Helmet>
      <header className="App-header">
        {(error && initialLoadAttempts > 2) ?
          <div className="smoothContainer"><span className="error">Fehler.</span> Bitte neu laden oder URL überprüfen.</div> :
          info ?
            <Invitation info={info} plural={plural} pax={pax} reinfeier={reinfeier} responded={responded} zusage={zusage} absage={absage} avWed={avWed} avThu={avThu} rowNumber={rowNumber} /> :
              <h1>Laden...</h1>
        }
      </header>
    </div>
  );
}

export default Invited;
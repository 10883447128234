import React, {useEffect, useState} from "react";
// import {Router, Link} from '@reach/router';
import './css/Response.css';
import {GoogleSpreadsheet} from "google-spreadsheet";


const Response = props => {
  const [submit, setSubmit] = useState(false);
  const [success, setSuccess] = useState(null);

  const [arrDay, setArrDay] = useState('Thu');

  useEffect(() => {
    if (props.accept && !submit) {
      if (arrDay === 'Wed') {
        document.getElementById('arrWed').classList.add('oBtnActive');
        document.getElementById('arrThu').classList.remove('oBtnActive');
      } else if (arrDay === 'Thu') {
        document.getElementById('arrWed').classList.remove('oBtnActive');
        document.getElementById('arrThu').classList.add('oBtnActive');
      } else {
        setArrDay('Thu');
      }
    }
  }, [props.accept, arrDay, submit]);

  const [zusagePax, setZusagePax] = useState(props.pax);
  const [mitbringen, setMitbringen] = useState(0);

  useEffect(() => {
    if (props.accept && !props.plural && !submit) {
      if (mitbringen) {
        setZusagePax(props.pax + 1);
        document.getElementById('mitbringen0').classList.remove('oBtnActive');
        document.getElementById('mitbringen1').classList.add('oBtnActive');
      } else {
        setZusagePax(props.pax);
        document.getElementById('mitbringen0').classList.add('oBtnActive');
        document.getElementById('mitbringen1').classList.remove('oBtnActive');
      }
    }
  }, [mitbringen, props.accept, props.plural, props.pax, submit]);

  const [veggie, setVeggie] = useState("nix");

  useEffect(() => {
    if (props.accept && !submit) {
      const nix = document.getElementById('nix');
      const vegetarisch = document.getElementById('vegetarisch');
      const vegan = document.getElementById('vegan');
      if (veggie === "nix") {
        nix.classList.add('oBtnActive');
        vegetarisch.classList.remove('oBtnActive');
        vegan.classList.remove('oBtnActive');
      } else if (veggie === "veggie") {
        nix.classList.remove('oBtnActive');
        vegetarisch.classList.add('oBtnActive');
        vegan.classList.remove('oBtnActive');
      } else if (veggie === "vegan") {
        nix.classList.remove('oBtnActive');
        vegetarisch.classList.remove('oBtnActive');
        vegan.classList.add('oBtnActive');
      }
    }
  }, [veggie, props.accept, submit]);

  const [sleepWed, setSleepWed] = useState(false);
  const [sleepThu, setSleepThu] = useState(false);

  useEffect(() => {
    if (props.accept && !submit) {
      const slWed = document.getElementById('sleepWed');
      const slThu = document.getElementById('sleepThu');
      const slWedLabel = slWed.nextSibling;
      const slThuLabel = slThu.nextSibling;
      if (sleepWed) {
        slWedLabel.classList.add('underline');
      } else {
        slWedLabel.classList.remove('underline');
      }
      if (sleepThu) {
        slThuLabel.classList.add('underline');
      } else {
        slThuLabel.classList.remove('underline');
      }
    }
  }, [props.accept, sleepWed, sleepThu, submit]);

  const handleSubmit = (accept) => {
    // console.log("handleSubmit");

    const submitToGoogle = async (accept) => {
      const SHEET_ID = process.env.REACT_APP_SHEET_ID;
      const doc = new GoogleSpreadsheet(SHEET_ID);
      const credentials = require('./credentials.json')

      await doc.useServiceAccountAuth(credentials);
      await doc.loadInfo();

      const sheet = doc.sheetsByIndex[0];
      const rows = await sheet.getRows();

      const today = new Date();
      const responseDate = today.getDate() + "." + (today.getMonth() + 1) + "." + today.getFullYear() + " " + today.getHours() + ":" + today.getMinutes();
      if (accept) {
        // console.log("Zusage Pax", zusagePax);
        rows[props.rowNumber].Zusage = zusagePax;
        rows[props.rowNumber].Absage = '';
        rows[props.rowNumber].Ankunft = (arrDay === 'Wed') ? 'Mi' : 'Do';
        rows[props.rowNumber].SchlafMi = sleepWed ? zusagePax : 0;
        rows[props.rowNumber].SchlafDo = sleepThu ? zusagePax : 0;
        rows[props.rowNumber].Veggie = veggie;
      } else {
        rows[props.rowNumber].Absage = true;
      }
      rows[props.rowNumber].Antwort = responseDate;
      await rows[props.rowNumber].save();
    }

    submitToGoogle(accept)
      .then(() => {
        setSubmit(true);
        setSuccess(true);
        console.log("Successful SUBMIT");
      })
      .catch((err) => {
        setSubmit(false);
        setSuccess(false);
        console.log("Error submitting", err);
      });

  }

  const acceptForm = () => {
    return (
      <>
        <h2 className="resTitle">Ja, {props.plural ? 'wir sind' : 'ich bin'} dabei! <span role="img"
                                                                                          aria-label="champaign-emoji">🍾</span>
        </h2>
        <div>An welchem Tag {props.plural ? 'kommt Ihr' : 'kommst Du'} an?<br/>
          <button className="inlineBtn outlineBtn mt-0-5rem" id="arrWed" onClick={() => setArrDay('Wed')}>Mittwoch (11.8.)
          </button>
          <button className="inlineBtn outlineBtn mt-0-5rem" id="arrThu" onClick={() => setArrDay('Thu')}>Donnerstag (12.8.)
          </button>
          <div className="mt-1rem smallerText textGray">Informationen zum Zeitplan <a className="link underline textGray" onClick={() => document.getElementById('scheduleHeadline').scrollIntoView({behavior: "smooth"})}><span role="img" aria-label="right down arrow">⤵️</span> siehe unten</a> <span role="img" aria-label="twinkle smiley">😉</span></div>
        </div>
        {props.plural ? '' : <div className="mt-2rem">Bringst Du noch jemanden mit?<br/>
          <button className="inlineBtn outlineBtn" id="mitbringen0" onClick={() => setMitbringen(false)}>Nein</button>
          <button className="inlineBtn outlineBtn" id="mitbringen1" onClick={() => setMitbringen(true)}>+1</button>
        </div>}
        <div className="mt-2rem">Etwas zu beachten zwecks der Mahlzeiten?<br/>
          <button className="inlineBtn outlineBtn" id="nix" onClick={() => setVeggie('nix')}>Nix</button>
          <button className="inlineBtn outlineBtn" id="vegetarisch" onClick={() => setVeggie('veggie')}>Vegetarisch
          </button>
          <button className="inlineBtn outlineBtn" id="vegan" onClick={() => setVeggie('vegan')}>Vegan</button>
        </div>
        <div className="mt-2rem">{props.plural ? 'Möchtet Ihr' : 'Möchtest Du'} übernachten?<br/>
          <div className="slCheckContainer">
            <input type="checkbox" id="sleepWed" className="slCheckbox" onClick={() => setSleepWed(!sleepWed)}/>
            <span className="slCheckLabel"
                  onClick={() => document.getElementById('sleepWed').click()}>Mittwoch (11.8.)</span>
          </div>
          <div className="slCheckContainer">
            <input type="checkbox" id="sleepThu" className="slCheckbox" onClick={() => setSleepThu(!sleepThu)}/>
            <span className="slCheckLabel" onClick={() => document.getElementById('sleepThu').click()}>Donnerstag (12.8.)</span>
          </div>
        </div>
        <div className="mt-2rem">
          <button className="smallestText outlineBtn backButton backButton--red" onClick={props.goBack}>Moment, nochmal
            zurück
          </button>
        </div>

        <button type="submit" className="resSubmit resAcceptBtn" onClick={() => handleSubmit(true)}>Absenden</button>

        <div className="">Bei Fragen o.ä. mich bitte gern <a
          href="mailto:marcusmerkel@me.com?subject=Frage zur Geburtstagsfeier / marcusfeiert.de">kontaktieren</a> <span
          role="img" aria-label="smiley">😊</span></div>
      </>
    );
  }

  const declineForm = () => {
    return (
      <>
        <h3>{props.plural ? 'Wir können' : 'Ich kann'} leider nicht dabei sein. <span role="img"
                                                                                      aria-label="sad emoji">😞</span>
        </h3>
        <div>
          <button className="smallestText outlineBtn backButton" onClick={props.goBack}>Moment, nochmal zurück</button>
        </div>
        <button type="submit" className="resSubmit resDeclineBtn" onClick={() => handleSubmit(false)}>Absenden</button>
      </>
    );
  }

  return (
    <div className={props.accept ? 'resContainer resContainer--accept' : 'resContainer resContainer--decline'}>
      {submit ?
        <div>
          {success ? <div>Danke für {props.plural ? 'Eure' : 'Deine'} Rückmeldung, {props.accept ?
            <b><br/>ich freu mich <i>sehr</i>! <span role="img" aria-label="smiley">😊</span></b> :
            <i>vielleicht beim nächsten Mal...!</i>}</div> :
            <div>Fehler beim Absenden. Bitte probiere es später noch einmal oder <a
              href="mailto:marcusmerkel@me.com?subject=Fehler beim Absenden auf marcusfeiert.de">schreib mir</a>.
            </div>}
        </div> :
        props.accept ? acceptForm() : declineForm()}
    </div>
  );
}

export default Response;
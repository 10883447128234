import Welcome from "./Welcome";
import Invited from "./Invited";
import './css/App.css';
import {Router} from '@reach/router';

function App() {
  // console.log(process.env.REACT_APP_SHEET_ID);
  return (
    <>
      <Router>
        <Welcome default />
        <Invited path="/geburtstag/:code"/>
      </Router>
      <div className="credentials">
        Photo by <a href="https://unsplash.com/@t_galler?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer">Thomas Galler</a> on <a href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noreferrer">Unsplash</a>
      </div>
    </>
  );
}

export default App;

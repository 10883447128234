import React from "react";

const Welcome = () => {
  return (
    <div className="App-header">
      <h1>Willkommen!</h1>
      <p>Mehr Informationen erhältst Du über Deinen persönlichen Login-Link!</p>
    </div>
  );
}

export default Welcome;